<template>
	<toast-welcom></toast-welcom>
	<router-view></router-view>
	<InstallPrompt v-if="false"/>
</template>

<script>

import ToastWelcom from "@/components/toastWelcom/ToastWelcom";
import InstallPrompt from './components/appInstallPrompt/AppInstallPrompt';
export default {
	name: 'App',
	components: {
		ToastWelcom,
		InstallPrompt
	},
	methods:{
	},
	async beforeMount() {
		console.log('Cargando app');
	}
}
</script>
