<script setup>
import Picker from '../amsul/pickadate/picker'
import $ from 'jquery';
import timePicker from '../amsul/pickadate/picker.time';
import {useStore} from 'vuex';
import {computed, onMounted} from 'vue';


// eslint-disable-next-line no-undef
const props = defineProps({
	startTime: {
		type: String,
		default: null
	}
});

let reservationTime = null;

const timePickerObject = computed({
	get: () => store.state.reservationForm.timePickerObject,
	set: (value) => store.commit('reservationForm/setSelectedRoom', {timePickerObject: value})
});

const onSetTimePicker = ()=>{
	const pickerList = document.querySelector('.picker__list');
	const newListItem = document.createElement('li');
	newListItem.classList.add('picker__list-item', 'picker__list-item--title');
	newListItem.textContent = 'Horas disponibles';
	pickerList.prepend(newListItem);
	store.commit('reservationForm/setSelectedTime', {selectedTime: timePickerObject.value.get('select', 'HH:i')});
}

const store = useStore();
const isInputTimeDisable = computed(() => store.getters['reservationForm/isInputTimeDisable']);

const selectedTime = computed(() => store.state.reservationForm.selectedTime);

onMounted(() => {
	if (props.startTime) {
		store.commit('reservationForm/setSelectedTime', {selectedTime:props.startTime});
	}

	const settings = {
		format: 'HH:i!h',
		formatSubmit: 'HH:i',
		hiddenName: true,
		clear: 'Borrar',
		onSet: onSetTimePicker
	}
	console.log(settings);
	const picker = Picker($)

	timePicker(picker, $);

	reservationTime = $("#input-time").pickatime(settings);
	store.commit('reservationForm/setTimePickerObjetc', {timePickerObject: reservationTime.pickatime('picker')});
});

</script>

<style>
@import '../amsul/pickadate/pickadate-wewelcom/default.css';
@import '../amsul/pickadate/pickadate-wewelcom/default.time.css';
</style>

<template>
	<div class="input-group">
    <span class="input-group-text">
      <i class="fas fa-clock"></i>
    </span>
		<input :disabled="!isInputTimeDisable" id="input-time" aria-label="" class="form-control rounded-end" placeholder="Hora:" type="text" v-model="selectedTime">
	</div>
</template>

