<!--<template src="./firstReservation.html"/>-->
<template v-else src="./passReservationCard.html"/>

<script>
import * as bootstrap from 'bootstrap';
import DatePicker from '@/components/pickdate/DatePicker.vue';
import TimePicker from "@/components/pickdate/TimePicker";
import ReservationForm from "@/components/ReservationForm";
import FooterWelcom from "@/components/core/FooterWelcom";
import QRCodeVue from "vue-qrcode";
import {mapGetters, mapMutations} from "vuex";
import CancelReservationModal from "@/components/mods/cancelReservationModal/CancelReservationModal";
import CancelAssistanceModal from "@/components/mods/cancelAssistanceModal/CancelAssistanceModal";
import UpdateReservationModal from "@/components/mods/updateReservationModal/UpdateReservationModal";

export default {

	inject: ['userRepository', 'reservationRepository'],
	props: {
		isFirstReservation: Boolean,
		reservation: Object,
		totalReservations: Number,
		index: Number,
	},
	watch: {
		user() {
			this.isValidateUser = this.user.type === 2;
		},
		reservation(){
			this.setupData()
			if (this.reservation.menuAccessEnabled === 1){
				this.redirectToMenu()
			}
		}
	},
	components: {
		TimePicker,
		DatePicker,
		ReservationForm,
		UpdateReservationModal,
		QRCodeVue,
		'cancel-reservation-modal': CancelReservationModal,
		'cancel-assistance-modal': CancelAssistanceModal,
		FooterWelcom
	},
	computed: {
		...mapGetters('user', ['user', 'firstReservation']),
		...mapGetters('config',['weWelcomUrl']),

		nameRestaurant() {
			return this.firstReservation ? this.firstReservation.venueParams.business_name : (this.latestVenueConfig? this.latestVenueConfig.resource_id : '')
		},
		nameFolderRestaurant() {
			return  this.firstReservation ? this.firstReservation.venueParams.resource_id : (this.latestVenueConfig? this.latestVenueConfig.resource_id : '')
		},
		updateOrderButtonText() {
			if (this.reservation.menuAccessEnabled === 0) {
				return 'Aquí podrás pedir'
			}
			return 'Comenzar a pedir'
		},
		invitedUsersString() {
			if (!this.invitedUsers || this.invitedUsers.length === 0) {
				return 'No hay asistentes';
			}

			let result = '';
			this.invitedUsers.forEach((invited, index) => {
				if (invited.userId === this.user.id) {
					result += 'Tú';
				} else {
					result += invited.name;
				}

				if (index !== this.invitedUsers.length - 1) {
					result += ', ';
				} else {
					result += '.';
				}
			});

			return result;
		},
		displayInvitedUsers() {
			// Obtiene los nombres de los usuarios invitados hasta el número especificado por reservation.pax
			// Agrega un indicador de invitados adicionales si hay más invitados que el límite de pax
			// if (this.invitedUsers.length > this.reservation.pax) {
			// 	namesToShow += `, +${this.invitedUsers.length - this.reservation.pax}`;
			// }

			return this.invitedUsers.slice(0, this.reservation.pax).map((invited, index) => {
				// Obtener el nombre del invitado y convertirlo a mayúsculas
				let name = String(invited.userId === this.user.id ? 'Tú' : invited.name);
				if (index === this.reservation.pax - 1 && this.invitedUsers.length > this.reservation.pax) {
					name += '.';
				}
				return name.toUpperCase()
			}).join(', ');
		}
	},
	data() {
		return {
			date: '',
			time: '',
			isMountedComponent: false,
			cancelAssistanceButtonText: 'Cancelar asistencia',
			shareInvitationLink: '',
			invitedUsers: [],
			isCreator: false,
			reservationHost: '',
			promotionDiscountDrink: '',
			isValidateUser: false,
			venueParams: null,
			nameShowQREventClick: 'WeWelcomx',

			textBtnStartOrdering: 'Comenzar a pedir'
		};
	},
	beforeMount() {
		console.log("Usuario en passreservationcard", this.user)
		this.shareInvitationLink = `https://${this.reservation.venueParams.subdomain}.wewelcom.io` + `/world/api/reservation/whatsappinvitationlink/${this.reservation.id}/${this.user.id}`;

		console.log('Informacion de las reservas',this.reservation)
		// Filtrar usuarios invitados (Creador: type 1), (Invitado: type 2), (Cancelada: type -1)
		this.invitedUsers = this.reservation.reservationUsers.filter(user => user.userId && user.type !== 1 && user.type !== -1);
		console.log('Estos son los datos de invitados', this.invitedUsers)

		this.isCreator = this.reservation.reservationUsers.filter(user => user.userId === this.user.id && user.type === 1).length > 0;
		this.reservationHost = this.reservation.reservationUsers.filter(user => user.type === 1)[0] ? this.reservation.reservationUsers.filter(user => user.type === 1)[0].name : 'Nombre del restaurante';

		this.venueParams = this.reservation.venueParams;
	},
	mounted() {
		this.setupData();
		this.initializeTooltips();
		this.isMountedComponent = true;
		this.promotionDiscountDrink = this.calculateDiscountPercentage(this.reservation.promotion ? this.reservation.promotion.discount : null);
		this.promotionDiscountFood = this.calculateDiscountPercentage();
	},
	methods: {
		...mapMutations('user', ['eraseReservation']),
		setupData() {
			const dateTime = new Date(this.reservation.startDateTime);

			const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
			const nameDay = daysOfWeek[dateTime.getDay()];
			const translator = {
				Sunday: "Dom.",
				Monday: "Lun.",
				Tuesday: "Mar.",
				Wednesday: "Mié.",
				Thursday: "Jue.",
				Friday: "Vie.",
				Saturday: "Sáb.",
			};
			const nameDayTranslated = translator[nameDay];

			const months = {
				Jan: 'Ene',
				Feb: 'Feb',
				Mar: 'Mar',
				Apr: 'Abr',
				May: 'May',
				Jun: 'Jun',
				Jul: 'Jul',
				Aug: 'Ago',
				Sep: 'Sep',
				Oct: 'Oct',
				Nov: 'Nov',
				Dec: 'Dic'
			};
			const month = months[dateTime.toLocaleString('en', {month: 'short'})];
			this.date = `${nameDayTranslated} ${dateTime.getDate()} ${month}`;

			const hours = dateTime.getHours();
			const minutes = dateTime.getMinutes();
			this.time = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
		},
		async qrClicked() {
			let agent = "WELCOM PASS TEST";

			let data = {
				"code": this.user.hostCode,
				"agent": agent,
				"type": 1,
				"text": "Escaneo de pass demo",
			}

			try {
				let reservationVenueSubdomain = this.venueParams.subdomain;
				let checkCodeResponse = await this.userRepository.registerLog(data, reservationVenueSubdomain);
				this.showToast('accessToMenuCorrectly');

				console.log("La respuesta del servidor para el check del código fue ");
				console.log(checkCodeResponse);

			} catch (e) {
				this.showToast('accessToMenuIncorrectly');
				console.log("Error: " + e);
			}
		},
		redirectToMenu() {
			window.location.href = `https://${this.reservation.venueParams.subdomain}.wewelcom.io/menu?r=${this.reservation.id}&uid=${this.user.hostCode}`;
		},

		calculateDiscountPercentage(discount) {
			if (discount !== null && discount !== 0 && discount !== '') {
				return `${discount / 100}%`;
			}
			return null;
		},
		showToast(toastId) {
			const toastElement = document.getElementById(toastId);
			const toastInstance = bootstrap.Toast.getOrCreateInstance(toastElement);
			toastInstance.show();
		},
		openCollapse(elementId) {
			let collapseElement = document.getElementById('collapse' + elementId);
			if (collapseElement) {
				let iconArrow = document.getElementById('iconArrow'+ elementId)
				let collapseInstance = bootstrap.Collapse.getInstance(collapseElement);
				if (!collapseInstance) {
					collapseInstance = new bootstrap.Collapse(collapseElement, {
						toggle: false
					});
				}
				if (collapseElement.classList.contains('show')) {
					collapseInstance.hide();
					iconArrow.classList.remove('fa-rotate-180')
				} else {
					collapseInstance.show();
					iconArrow.classList.add('fa-rotate-180')
				}
			}
		},
		initializeTooltips() {
			const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
			tooltipTriggerList.forEach(tooltipTriggerEl => {
				new bootstrap.Tooltip(tooltipTriggerEl);
			});
		},
		shouldRenderCol6() {
			return (this.isCreator && !this.reservation.finishedDateTime && this.reservation.menuAccessEnabled !== 1) ||
				(!this.isCreator && !this.reservation.finishedDateTime) ||
				(!this.isCreator && this.reservation.finishedDateTime) ||
				(this.isCreator && this.reservation.finishedDateTime);
		},
	}
};
</script>
