<template>
	<!--	INVITACION RESERVA-->
	<div class="container-section">

		<section class="border-responsive">
			<div class="section-header">
				<div class="section-subtitle">
					<span v-if="isValidReservation">{{ hostUserName }} ha reservado y cuenta contigo!</span>
					<span v-else>Reserva a nombre de <span class="fw-semibold text-center text-md-start">{{ hostUserName }}</span>.</span>
				</div>
			</div>
		</section>

		<div class="container p-0">
			<div class="card">
				<div class="card-header">
					<h3>{{ date }}</h3>
				</div>
				<div class="row g-0 justify-content-center">
					<div class="align-items-center h-100">
						<div class="info-inblock info-inblock-primary">
							{{ time }} h
							<div class="vr-point"></div>
							{{ reservationInfo.pax }} Pers
							<div class="vr-point"></div>
							{{ reservationInfo.roomName }}
						</div>
						<div class="hidden display-md">
							<ul class="list-group list-group-flush">
								<li class="list-group-item"></li>
							</ul>
						</div>
					</div>
					<div class="col-md-8">
						<div class="card-body h-100 align-content-center">

							<div v-if="isValidReservation && !this.user">
								<p>¿Cómo prefieres que te llamemos?</p>
								<!--								v-if="selectedInvitedUser === 1 || !invitedUsers.length"-->
								<div class="input-group mb-3">
									<span class="input-group-text"><i class="fas fa-user"></i></span>
									<input type="text" class="form-control" placeholder="Tu nombre (sin apellidos)" v-model="signupName" @focus="deselectCheckboxes">
								</div>
								<p v-if="(!selectedInvitedUser && !invitedUsers.length)">Ingresa tu nombre y confirma tu asistencia.</p>

							</div>

							<div v-if="invitedUsers.length" class="text-start">
								<div class="fw-bold" v-if="isValidReservation">
									Asistentes confirmados:
								</div>
								<div v-else>Asistentes a la reserva</div>

								<div v-for="invitedUser in invitedUsers" :key="invitedUser.uid" class="form-check">
									<input v-if="isValidReservation" class="form-check-input" v-model="selectedInvitedUser" type="radio" name="inlineRadioOptions" :id="invitedUser.uid" :value="invitedUser.uid" :disabled="invitedUser.typeHost === 2">
									<label class="form-check-label" :for="invitedUser.uid">{{ invitedUser.name }}</label>
								</div>
								<!--								!isPaxLimit &&-->
								<!--								<div v-if="isValidReservation" class="form-check">
																	<input class="form-check-input" v-model="selectedInvitedUser" type="radio" name="inlineRadioOptions" :id="1" :value="1">
																	<label class="form-check-label" :for="1">Soy alguien más</label>
																</div>-->
							</div>
							<!--							&& !isPaxLimit-->
							<!--							<div v-if="selectedInvitedUser === 1 || (!selectedInvitedUser && !invitedUsers.length) || isValidReservation ">
															<p v-if="selectedInvitedUser === 1"> ¿No eres ninguno de ellos?
																<b>Dinos cómo prefieres que te llamemos.</b></p>
															<p v-if="(!selectedInvitedUser && !invitedUsers.length)">Ingresa tu nombre y confirma tu asistencia.</p>
															<div v-if="selectedInvitedUser === 1 || !invitedUsers.length" class="input-group">
																<span class="input-group-text"><i class="fas fa-user"></i></span>
																<input type="text" class="form-control" placeholder="Ingresa tu Nombre (sin apellidos):" v-model="signupName">
															</div>
														</div>-->


							<div class="card-content-btn">
								<button
									v-if="isValidReservation"
									class="btn btn-primary w-100 mt-3"
									@click="submitAcceptInvitationReservation(false)"
									:disabled="(!signupName && (selectedInvitedUser === 1 || !selectedInvitedUser))"
								>
									{{ acceptButtonText }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div id="showAlertRedirect" class="modal fade" tabindex="-1" aria-labelledby="modalUserFoundByTokenLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered ">
			<div class="modal-content border-secondary border-1">
				<div class="modal-body text-center">
					<p class="fs-3">
						Cuenta encontrada en WePass. Accediendo a tu espacio…
					</p>
					<SpinnerIcon class="fw-bold fs-3"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import * as bootstrap from "bootstrap";
import {dateFormatterService} from "@/service/dateFormatterService";
import {showModal} from "@/utils";
import SpinnerIcon from "@/components/core/icons/SpinnerIcon";

export default {
	name: "InvitationReservation",
	components: {SpinnerIcon},
	inject: {
		reservationRepository: 'reservationRepository',
	},
	data() {
		return {
			selectedInvitedUser: null,
			date: '',
			signupName: null,
			textStandard: 'Confirmar asistencia',
			textLoading:'Confirmando...',
			isLoadingBtn: false,
		}
	},
	computed: {
		...mapState('user', ['user']),
		...mapGetters('invitation', ['isValidReservation', 'reservationInfo', 'invitedUsers', 'hostUserName']),

		acceptButtonText() {
			return this.isLoadingBtn ? this.textLoading : this.textStandard;
		}
	},
	watch: {
		selectedInvitedUser(newVal) {
			if (newVal && this.signupName) {
				this.signupName = '';
			}
		}
	},
	async beforeMount() {
		console.log('Antes de cargar componente invitación reserva, usuario: ', this.user);

		this.redirectWePass()

		this.date = dateFormatterService.formattedDate(this.reservationInfo.startDateTime);
		this.time = dateFormatterService.formattedTime(this.reservationInfo.startDateTime);

	},
	mounted() {
		console.log('Invitación reserva, usuario: ', this.user);

		this.redirectWePass()
	},
	methods: {
		redirectWePass() {
			if (this.user && this.reservationInfo.menuAccessEnabled === 0) {
				showModal('showAlertRedirect')
				setTimeout(() => {
					this.submitAcceptInvitationReservation()
				}, 4000);
			}
		},
		async submitAcceptInvitationReservation() {
			if (event) {
				event.preventDefault();
			}
			if (this.user) {
				const existingUser = this.reservationInfo.reservationUsers.find(user => user.userId === this.user.id && user.typeHost > 0);
				if (existingUser) {
					this.selectedInvitedUser = existingUser.uid;
					window.location.href = `${window.location.origin}/?uid=${this.selectedInvitedUser}`
					return;
				} else {
					const result = await this.reservationRepository.addInvitedUser(this.reservationInfo.id, this.user.id);
					console.log(result)
					if (result.result === 0) {
						window.location.href = `${window.location.origin}/?uid=${this.user.hostCode}`
					}
					return
				}
			}

			if (this.selectedInvitedUser === 1 || !this.selectedInvitedUser) {
				//TODO: Si agrega el nombre
				this.isLoadingBtn = true;
				console.log('Que es ', this.selectedInvitedUser === 1, !this.selectedInvitedUser)
				if (!this.signupName) {
					alert('El nombre debe tener un valor');
				}

				const response = await this.reservationRepository.addUnknownInvitedUser(this.reservationInfo.id, this.hostCode, this.signupName);

				if (response.result === 0) {
					window.location.href = `${window.location.origin}/?uid=${response.uid}`;
					this.isLoadingBtn = false;
				} else {
					this.isLoadingBtn = false;
				}
			} else {
				//TODO: Si selecciona el usuario desde el checkbox
				this.isLoadingBtn = true;
				const user = {...this.reservationInfo.reservationUsers.find(user => user.uid === this.selectedInvitedUser)};
				console.log('Que es esto otro', this.selectedInvitedUser, !this.selectedInvitedUser)

				if (user.typeHost === 2) {
					console.log('Que es typeHost', this.selectedInvitedUser, !this.selectedInvitedUser)

					await this.userRepository.sendPass(user.uid);
					const modal = document.getElementById('modal-check-inbox-reservation-invitation');
					const bootstrapModal = new bootstrap.Modal(modal);
					bootstrapModal.show();
					this.isLoadingBtn = false;


				}
				window.location.href = `${window.location.origin}/?uid=${this.selectedInvitedUser}`;
				this.isLoadingBtn = false;
			}
		},

		deselectCheckboxes() {
			this.selectedInvitedUser = null;
		}
	}
}
</script>

<style scoped>

</style>
