import {findClosestReservation} from "@/utils";

const state = {
	actionParam: null,
	reservationIdParam: null,
	toast: {
		showToast: false,
		toastMessage: "",
		idToast: ""
	},
};

const getters = {
	actionParam(state) {
		return state.actionParam;
	},
	reservationIdParam(state) {
		return state.reservationIdParam;
	},
	toast: state => {
		return state.toast;
	},
};

const mutations = {

	setToast(state, payload) {
		state.toast = payload.toast;
	},
};

const actions = {
	loadQueryParamsToState({rootGetters }) {
		const queryParams = new URLSearchParams(window.location.search);
		state.actionParam = queryParams.get('action');

		switch (state.actionParam) {
			case "1":{
				state.reservationIdParam = queryParams.get('id');
				break;
			}
			case "2":{
				const dateParam = queryParams.get('date');
				const timeParam = queryParams.get('time');
				const startDateTime = new Date(`${dateParam} ${timeParam}`);
				const userReservations = rootGetters['user/userReservations'];
				state.reservationIdParam = findClosestReservation(userReservations, startDateTime);
				break;
			}
			default:{
				break;
			}
		}

	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
