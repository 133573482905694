<template>
	<div class="content-center-justify" :data-bs-theme="venueParams ? venueParams.resource_id : ''">
		<header-container :image-restaurant="venueParams.resource_id" :subTitle="venueParams.business_name" :text-title="textTitle" :reservation-info="reservationInfo"/>

		<InvitationReservation v-if="!isInvitationMenu && reservationInfo"/>

		<InvitationMenu v-if="isInvitationMenu"></InvitationMenu>

		<!-- Modal -->
<!--		<div v-if="user" class="modal fade" id="modalUserFoundByToken" tabindex="-1" aria-labelledby="modalUserFoundByTokenLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header">
						<div class="modal-title h4" id="modalUserFoundByTokenLabel">¡Hola {{user.name}}!</div>
						<button type="button" class="btn-close hidden" data-bs-dismiss="modal" aria-label="Cerrar"></button>
					</div>
					<div class="modal-body text-center">
						<p>
							<span v-if="isValidReservation && !isInvitationMenu"><b>{{ hostUserName }}</b> ha reservado y cuenta contigo!</span>
							<span v-if="isInvitationMenu && isValidReservation"><b>{{ hostUserName }}</b> te ha compartido la carta. ¡Acepta y pide desde tu móvil!</span>
						</p>

						<div v-if="isValidReservation && !isInvitationMenu" class="container p-0">
							<div class="card">
								<div class="card-header">
									<h3>{{ date }}</h3>
								</div>
								<div class="row g-0 justify-content-center">
									<div class="align-items-center h-100">
										<div class="info-inblock info-inblock-primary">
											{{ time }} h
											<div class="vr-point"></div>
											{{ reservationInfo.pax }} personas
											<div class="vr-point"></div>
											{{ reservationInfo.roomName }}
										</div>
									</div>
									<div class="col-md-8">
										<div class="card-body h-100 align-content-center">
											<div v-if="invitedUsers.length" class="text-start">
												<div><b>Asistentes confirmados</b></div>
												<span v-for="(invitedUser, index) in invitedUsers" :key="invitedUser.uid">
                                            <span>{{ invitedUser.name }}</span>
                                            <span v-if="index !== invitedUsers.length - 1">, </span>
                                            <span v-if="index === invitedUsers.length - 1">.</span>
                                        </span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Soy otra persona</button>

						<button v-if="!isInvitationMenu && reservationInfo" type="button" class="btn btn-primary" @click="submitAcceptInvitationReservation(true)">Aceptar</button>
						<button v-if="isInvitationMenu" type="button" class="btn btn-primary" @click="submitAcceptInvitationMenu(true)">Acceder</button>
					</div>
				</div>
			</div>
		</div>-->
		<!--	<ExitConfirmationModal/>-->
		<footer-welcom></footer-welcom>
	</div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import HeaderContainer from "@/components/core/HeaderContainer";
import FooterWelcom from "@/components/core/FooterWelcom";
import InvitationReservation from "@/components/invitationReservation/InvitationReservation";
import InvitationMenu from "@/components/invitationMenu/InvitationMenu";
// import ExitConfirmationModal from "@/components/core/ExitConfirmationModal";


export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: 'Invitation',
	components: {
		HeaderContainer: HeaderContainer,
		FooterWelcom,
		// ExitConfirmationModal
		InvitationReservation,
		InvitationMenu
	},
	inject: {
		userRepository: 'userRepository',
		allergenRepository: 'allergenRepository',
		reservationRepository: 'reservationRepository',
		manageToken: 'manageToken',
		dateFormatterService: 'dateFormatterService'
	},

	data() {
		return {
			signupEmail: '',
			signupGender: null,
			signupName: null,
			signupTerms: false,
			reservationWid: '',
			currentStep: 1,
			showErrorMessageForm: false,
			errorMessage: '',
			responseMessage: 'Por favor, introduce una dirección de correo electrónico válida.',
			date: '',
			time: '',
			textTitle: 'Invitación',
		};
	},
	async beforeMount() {
		console.log('Antes de cargar componente invitacion, este es el usuario: ', this.user);
		},
	mounted() {
		// this.userDetectionOpeningModal()
		// this.removeQueryParams()
	},
	computed: {
		...mapState('user', ['user', 'userReservations']),
		...mapGetters('invitation', ['venueParams', 'hostUserName', 'isInvitationMenu','reservationInfo']),
		// isPaxLimit() {
		// 	return this.paxLimitConfirmation(this.reservationInfo.pax, this.reservationInfo.reservationUsers);
		// },

	},
	methods: {

		removeQueryParams() {
			let url = window.location.href;
			const urlParts = url.split('?');

			if (urlParts.length >= 2) {
				url = urlParts[0];
				window.history.replaceState({}, document.title, url);
			}
		},
		paxLimitConfirmation(pax, reservationUsers) {
			const maxPax = pax;
			const numberUserConfirm = reservationUsers.length;
			console.log('Número máximo de ocupantes permitidos:', maxPax);
			console.log('Número de usuarios confirmados:', numberUserConfirm);

			if (numberUserConfirm >= maxPax) {
				console.log('Se ha alcanzado el límite de ocupantes.' + true);
				return true;
			}
			console.log('Todavía hay espacio disponible.' + false);
			return false;
		},

		// userDetectionOpeningModal() {
		// 	if (this.user && this.reservationInfo) {
		// 		const modalId = document.getElementById('modalUserFoundByToken');
		//
		// 		if (modalId) {
		// 			const modalElement = new bootstrap.Modal(modalId, {
		// 				backdrop: 'static',
		// 				keyboard: false
		// 			});
		//
		// 			modalElement.show();
		// 		} else {
		// 			console.error('Modal element with ID "modalUserFoundByToken" not found.');
		// 		}
		// 	} else {
		// 		console.error('User or reservationInfo is not available yet.');
		// 	}
		// },

	},
}
</script>
