import {createApp} from 'vue'
import VueScrollTo from 'vue-scrollto';
import {createRouter, createWebHistory} from 'vue-router'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../public/assets/css/style.css';

import store from './store';
import {configRepository} from "@/service/repository/configRepository";
import {userRepository} from "@/service/repository/userRepository";
import {reservationRepository} from "@/service/repository/reservationRepository";
import {allergenRepository} from "@/service/repository/allergenRepository";

import '@fortawesome/fontawesome-free/css/all.css';
import WePass from "@/views/wePass/WePass";
import Invitation from "@/views/invitation/Invitation";
import {cookieManager} from "@/service/cookieManager";
import {faviconService} from "@/service/faviconService";
import {dateFormatterService} from "@/service/dateFormatterService";
import './registerServiceWorker'

const routes = [
	{
		path: '/',
		component: WePass,
		name: 'WePass',
		meta: {
			title: 'WEPASS | La nueva hostelería'
		}
	},
	{
		path: '/invitacion',
		component: Invitation,
		name: 'Invitation',
		meta: {
			title: 'Invitación | La nueva hostelería'
		}
	},
	{
		path: '/logout',
		component:
		Invitation,
		name: 'Logout',
		meta: {
			title: 'Logout'
		}
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: '/redirect',
		meta: {
			title: ''
		}
	},
]

export const router = createRouter({
	history: createWebHistory(),
	routes
})

const loadUser = async (uid = null) => {
	if (uid) {
		console.log("Detectado UID: " + uid);
		await store.dispatch('user/find', uid)
	} else {
		const token = cookieManager.getToken();
		if (token) {
			console.log("No hay UID, usando token: ")
			await store.dispatch('user/loginWithToken', token)
		}
	}
	return store.getters['user/user'];
}

router.beforeEach(async (to, from, next) => {
	let websocketUrl = store.getters['config/websocketUrl'];
	let user = null;

	if (!websocketUrl) {
		await store.dispatch('config/loadSystemConfig');
		store.commit('wePass/loadQueryParamsToState');
		websocketUrl = store.getters['config/websocketUrl'];
		console.log("la url del socket es ", websocketUrl)
		console.log("Cargando ruta: " + to.name)
	}

	// faviconService.changeFavicon(store.getters['config/weWelcomUrl'])

	faviconService.changeFaviconByRoute(to.path);

	const uid = to.query.uid;

	switch (to.name) {
		case "WePass":
			console.log('WePass desde el switch');
			user = await loadUser(uid);
			if (user == null){
				//console.log(`el usuario con uid ${uid} es null`, user)
				window.location.href = store.getters['config/weWelcomUrl'];
			}
			await store.dispatch('user/loadUserReservations')
			await store.dispatch('wePass/loadQueryParamsToState')
			break;
		case "Logout":
			cookieManager.clearToken();
			location.href = store.state.config.weWelcomUrl;
			break;
		case "Invitation":
			console.log('Invitacion desde el switch');
			user = await loadUser(uid);
			await store.dispatch('invitation/loadInvitationInfo', {user})
			break;
		default:
			break;
	}

	next();
});

router.afterEach((to) => {
	if (to.meta && to.meta.title) {
		document.title = to.meta.title;
	}
});

let deferredPrompt;

window.addEventListener('beforeinstallprompt', (e) => {
	// Prevent the mini-infobar from appearing on mobile
	e.preventDefault();
	// Stash the event so it can be triggered later.
	deferredPrompt = e;
	// Update UI to notify the user they can add to home screen
	showInstallPromotion();
	// alert("Pero bueno")
});

function showInstallPromotion() {
	const installButton = document.getElementById('installButton');
	if (installButton) {
		//installButton.style.display = 'block';

		installButton.addEventListener('click', (e) => {
			// Hide the app provided install promotion
			installButton.style.display = 'none';
			// Show the install prompt
			deferredPrompt.prompt();
			console.log(e);
			// Wait for the user to respond to the prompt
			deferredPrompt.userChoice.then((choiceResult) => {
				if (choiceResult.outcome === 'accepted') {
					console.log('User accepted the A2HS prompt');
				} else {
					console.log('User dismissed the A2HS prompt');
				}
				deferredPrompt = null;
			});
		});
	} else {
		console.log('Install button not found');
	}
}

const app = createApp(App);

app.use(VueScrollTo);

app.use(router);
app.use(store);
app.provide('dateFormatterService', dateFormatterService);
app.provide('configRepository', configRepository);
app.provide('manageToken', cookieManager);
app.provide('userRepository', userRepository);
app.provide('reservationRepository', reservationRepository);
app.provide('allergenRepository', allergenRepository);

app.mount('#app')
