<script setup>
import DatePicker from "@/components/pickdate/DatePicker";
import TimePicker from "@/components/pickdate/TimePicker";
import {computed, onBeforeMount, inject, onBeforeUnmount, ref, watch} from "vue";
import {useStore} from "vuex";

// eslint-disable-next-line no-undef
const props = defineProps({
  reservation: {
    type: Object,
    default: null
  }
});

const reservationRepository = inject('reservationRepository');
const store = useStore();

const selectedDate = computed(() => store.state.reservationForm.selectedDate);
watch(selectedDate, (newValue) => {
	if(newValue){
		checkAvailability({reservationWid: props.reservation.id, client: props.reservation.venueParams.subdomain})
	}
});

const selectedPax = ref(store.state.reservationForm.selectedPax || (props.reservation.pax ?? null));
watch(selectedPax, (newValue) => {
	store.commit('reservationForm/setSelectedPax', { selectedPax: newValue });
	if(newValue){
		checkAvailability({reservationWid: props.reservation.id, client: props.reservation.venueParams.subdomain})
	}
});

const selectedRoom = ref(store.state.reservationForm.selectedRoom || (props.reservation.roomId ?? null));
watch(selectedRoom, (newValue) => {
	store.commit('reservationForm/setSelectedRoom', { selectedRoom: newValue });
	if(newValue > 0){
		checkAvailability({reservationWid: props.reservation.id, client: props.reservation.venueParams.subdomain})
	}
});

const availableRooms = computed({
	get: () => store.state.reservationForm.availableRooms,
	set: (value) => store.commit('reservationForm/setAvailableRooms', {availableRooms: value})
});

const checkAvailability = async ({reservationWid = null, client})=>{
	console.log('Comprobando la disponibilidad');
	const data = {
		dateFrom: selectedDate.value,
		date: selectedDate.value,
		roomId: selectedRoom.value,
		pax: selectedPax.value,
	}

	reservationWid ? data.reservationWid = reservationWid : null
	const timeSlotsResponse = await reservationRepository.checkAvailability(data, client);
	const timeSlots = timeSlotsResponse.timeSlots;
	const enabledTimes = timeSlots
		.filter(slot => slot.countAvailable > 0)
		.map(slot => slot.timeSlot);

	const timesToEnable = enabledTimes.map(time => [parseInt(time.split(':')[0], 10), parseInt(time.split(':')[1], 10)]);
	store.commit('reservationForm/setAllTimesSlots', {allTimeSlots: timeSlots});
	store.commit('reservationForm/setTimesSlotsEnable', {timesSlotsEnable: timesToEnable});
}

onBeforeMount(async ()=>{

	if (props.reservation) {
		const availableRooms = await reservationRepository.getAvailableRooms(props.reservation.venueParams.subdomain);
		store.commit('reservationForm/setAvailableRooms', {availableRooms: availableRooms})
		store.commit('reservationForm/setSelectedPax', {selectedPax: props.reservation.pax});
		store.commit('reservationForm/setSelectedRoom', {selectedRoom: props.reservation.roomId});

		await checkAvailability({reservationWid: props.reservation.id, client: props.reservation.venueParams.subdomain});
	}
})

onBeforeUnmount(() => {
	store.commit('reservationForm/clearData')
});

</script>

<template>

  <form class="row row-column-gap row-margin-x form-w">
    <DatePicker :start-date="reservation ? reservation.startDateTime : null"/>
	<div class="col-12 col-md-6 container-select">
		<select v-model="selectedRoom" class="form-select" aria-label="">
			<option value="-1" selected>Zona:</option>
			<option v-for="room in availableRooms" :key="room.room_id" :value="room.room_id">
				{{ room.name }}
			</option>
		</select>
	</div>
	<div class="col-6">
      <div class="input-group"><span class="input-group-text"><i class="fas fa-user-plus"></i></span>
        <input id="input-pax"  type="number" class="form-control"
               aria-label="" placeholder="Comensales:" v-model="selectedPax">
      </div>
    </div>

	<div class="col-6">
      <TimePicker :start-time="reservation ? reservation.startDate : null" />
    </div>

  </form>

</template>

