<template>
	<transition name="fade">
		<section v-if="showInstallPrompt" class="install-prompt">
			<div class="container">
				<p>¡Crea un acceso directo a WEPASS! Mantén tu código QR siempre a mano para una experiencia sin esperas.</p>

				<div class="row">
					<div class="col-6">
						<button class="btn btn-outline-secondary w-100" @click="dismissPrompt">Ahora no</button>
					</div>
					<div class="col-6">
						<button class="btn btn-secondary w-100" @click="installApp">Crear acceso</button>
					</div>
				</div>
			</div>
		</section>
	</transition>
</template>

<script>
export default {
	data() {
		return {
			showInstallPrompt: false,
			deferredPrompt: null
		};
	},
	methods: {
		showPrompt(event) {
			event.preventDefault();
			this.deferredPrompt = event;
			this.showInstallPrompt = true;
		},
		installApp() {
			if (this.deferredPrompt) {
				this.deferredPrompt.prompt();
				this.deferredPrompt.userChoice.then((choiceResult) => {
					if (choiceResult.outcome === 'accepted') {
						console.log('User accepted the A2HS prompt');
					} else {
						console.log('User dismissed the A2HS prompt');
					}
					this.deferredPrompt = null;
					this.showInstallPrompt = false;
				});
			}
		},
		dismissPrompt() {
			this.showInstallPrompt = false;
		}
	},
	mounted() {
		window.addEventListener('beforeinstallprompt', this.showPrompt);
	},
	beforeUnmount() {
		window.removeEventListener('beforeinstallprompt', this.showPrompt);
	}
};
</script>
