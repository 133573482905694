import VueScrollTo from "vue-scrollto";
import * as bootstrap from "bootstrap";
export async function sendRequest({data, url, method, contentType = 'application/x-www-form-urlencoded', token = null}) {
    try {

		let options = {
			method: method,
			headers: {
				"Content-Type": contentType
			},
		};

		if (token) {
			options.headers['Authorization'] = `Bearer ${token}`;
		}

        const dataToSend = contentType.includes('json') ? JSON.stringify(data) : new URLSearchParams(data);
		options.body = dataToSend;

        const response = await fetch(url, options);

        if (!response.ok) {
            throw new Error("Error en la solicitud: " + response.status);
        }

        return await response.json();


    } catch (error) {
        console.error("Error:", error);
    }
}

export async function sendRequestJson({data, url, method, token = null}) {

    let options = {
        method: method,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },

    };

    if (token) {
        options.headers['Authorization'] = `Bearer ${token}`;
    }

    if (data) {
        options.body = JSON.stringify(data);
    }

	//console.log("La url en el fetch es "+url)
    try {
        const response = await fetch(url, options);

        if (!response.ok) {
            return response.status;
        }

        return await response.json();

    } catch (error) {
        console.error("Error:", error);
    }
}

const monthsFull = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto',
    'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
];

export const monthsShor = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

const weekdaysFull = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

const weekdaysShort = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sáb'];

export const settingsDatePicker = {
    format: 'dddd, d !de mmmm',
    hiddenName: true,
    formatSubmit: 'yyyy-mm-dd',
    firstDay: 1,
    monthsFull: monthsFull,
    monthsShort: monthsShor,
    weekdaysFull: weekdaysFull,
    weekdaysShort: weekdaysShort,
    today: 'Hoy',
    clear: 'Borrar',
    close: 'Cerrar',
    labelMonthNext: 'Mes siguiente',
    labelMonthPrev: 'Mes anterior',
    labelMonthSelect: 'Selecciona un mes',
    labelYearSelect: 'Selecciona un año',
    klass: {
        highlighted: false,
    },
    //min: [2023, 11, 15],
    min: true,
    max: 60,
    disable: [
        //1
        //{from: [2024, 11, 24], to: [16, 0]},
        //[2023, 11, 25],
        //[2023, 11, 26],
        //[2023, 11, 31],
        //[2024, 0, 1],
        // {from: [22, 15], to: [23, 0]},
    ],
};

export function formatDateTime(dateString) {
    const date = new Date(dateString);

    const dayOfWeek = weekdaysFull[date.getDay()];
    const dayOfMonth = date.getDate();
    const monthName = monthsFull[date.getMonth()];

    return `${dayOfWeek}, ${dayOfMonth} de ${monthName}`;
}

export function scrollToVue({element, time = 3500, focus = false}){
	const options = {
		easing: [0.25, 0.1, 0.25, 1.0],
		duration: time,
		// offset: -(document.getElementById('wewelcome-menu').offsetHeight)
	};
	// const menuElement = document.getElementById('wewelcome-menu');
	// menuElement ? options.offset = -menuElement.offsetHeight : null;
	document.documentElement.style.scrollBehavior = 'auto';
	// this.showToast('productSavedCorrectly');

	VueScrollTo.scrollTo(element, options);
	if(focus){
		element.focus();
	}
}

export function hideAllModals() {
	const modals = document.querySelectorAll('.modal');
	modals.forEach(function (modal) {
		var bootstrapModal = bootstrap.Modal.getInstance(modal); // Get the Bootstrap modal instance
		if (bootstrapModal) {
			bootstrapModal.hide(); // Hide the modal
		}
	});
}

export function findClosestReservation(reservations,startDateTime ){
	const calculateTimeDifference = (date1, date2) => Math.abs(date1 - date2);

	let closestReservation = reservations.reduce((closest, reservation) => {
		const reservationDateTime = new Date(reservation.startDateTime);
		const difference = calculateTimeDifference(startDateTime, reservationDateTime);

		return (difference < closest.smallestDifference)
			? { id: reservation.id, smallestDifference: difference }
			: closest;
	}, { id: null, smallestDifference: Infinity });

	return closestReservation.id;
}

export function showModal(modalId) {
	const modalElement = document.getElementById(modalId);
	if (!modalElement) {
		console.error(`Modal element not found: ${modalId}`);
		return;
	}

	console.log(`Existe modal con id ${modalId}`);
	let modalInstance = bootstrap.Modal.getInstance(modalElement);
	if (!modalInstance) {
		console.log("No existía el modal, creating a new instance");
		modalInstance = new bootstrap.Modal(modalElement, {
			backdrop: 'static',
			keyboard: false
		});
	} else {
		console.log("Ya existía el modal del qr, using existing instance");
	}

	modalInstance.show();
}
