<!--<template src="./wepass.html"></template>-->

<template>
	<div style="height: 100vh; display: flex; flex-direction: column">
		<header-container :text-name="user.name+'!'" :text-title="textTitle"/>

		<q-r-container/>

		<pass-reservation-container></pass-reservation-container>

		<invalid-reservation-modal></invalid-reservation-modal>
		<footer-welcom></footer-welcom>
	</div>
</template>


<script>
import * as bootstrap from 'bootstrap';
import {mapGetters, mapState} from 'vuex';

import {WebSocketClient} from "@/service/webSocketClient";

import FooterWelcom from "@/components/core/FooterWelcom";
import PassReservationContainer from "@/components/passReservationContainer/PassReservationContainer";
import HeaderContainer from "@/components/core/HeaderContainer.vue";
import InvalidReservationModal from "@/components/mods/invalidReservationModal/InvalidReservationModal";
import QRContainer from "@/components/core/QRContainer";

export default {
	name: 'WePass',
	components: {
		QRContainer,
		HeaderContainer,
		PassReservationContainer,
		InvalidReservationModal,
		FooterWelcom,
	},
	inject: {
		userRepository: 'userRepository',
		allergenRepository: 'allergenRepository',
		reservationRepository: 'reservationRepository',
		manageToken: 'manageToken',
	},
	data() {
		return {
			webSocketClient: WebSocketClient,
			isCreator: false,
			isCannotBeReserved: null,
			saveAllergensButtonContent: 'Guardar',
			contactMessage: "",
			allergens: null,
			phoneInputFirst: null,
			textTitle:''
		};
	},

	beforeMount() {
		console.log('Primera reserva:', this.firstReservation)

		// const theme = this.firstReservation && this.firstReservation.venueParams && this.firstReservation.venueParams.resource_id ?
		// 	this.firstReservation.venueParams.resource_id :
		// 	(this.latestVenueConfig && this.latestVenueConfig.resource_id ? this.latestVenueConfig.resource_id : '');

		// this.isCreator = this.firstReservation.reservationUsers.filter(user => user.userId === this.user.id && user.type === 1).length > 0;
		this.webSocketClient = new WebSocketClient(this, this.websocketUrl, this.websocketPort);
		this.webSocketClient.connect(this.user.hostCode);

		// document.querySelector('#app').setAttribute('data-bs-theme', theme);

			console.log('TENEMOS RESERVA')
			if(this.user) {
				console.log('SOY CREADOR')
				this.textTitle = '¡Hola'
			} else{
			console.log('SOY INVITADO')
			this.textTitle = 'Asistencia confirmada'
			}

	},
	async mounted() {
		if (this.actionParam) {
			console.log('EL ACTION PARAMM!!!');
			let modal = null;
			switch (this.actionParam) {
				case '1': {
					if(this.userReservations.find(reservation => reservation.id === this.reservationIdParam)){
						modal = document.getElementById('modalCancelReserve-' + this.reservationIdParam);
					}else{
						modal = document.getElementById('modalInvalidCancelReserve');
					}

					break;
				}
			case '2':{
				modal = document.getElementById('modalUpdateReserve-' + this.reservationIdParam)
			}

			}

			if(modal){
				let bootstrapModal = new bootstrap.Modal(modal);
				bootstrapModal.show();
			}
		}
		this.removeQueryParams();
	},
	computed: {
		...mapState('user', ['user', 'userReservations']),
		...mapGetters('user', ['firstReservation', 'latestVenueConfig']),
		...mapGetters('config', ['websocketUrl', 'websocketPort', 'weWelcomUrl']),
		...mapGetters('wePass', ['actionParam', 'reservationIdParam']),
	},
	methods: {
		showToast(toastId) {
			const toastElement = document.getElementById(toastId);
			const toastInstance = bootstrap.Toast.getOrCreateInstance(toastElement);
			toastInstance.show();
		},

		async handleSaveAllergens() {
			this.saveAllergensButtonContent = "Guardando <i class=\"fa fa-spinner fa-spin\"></i>";
			const result = await this.userRepository.updateAllergens(this.user.id, Array.from(this.user.allergens));
			if (result.result === 0) {
				this.showToast('allergiesSavedCorrectly');
				this.saveAllergensButtonContent = "Guardar";
			}
		},

		changeStatusAllergen(allergenId) {
			this.user.allergens.includes(allergenId) ?
				this.user.allergens = this.user.allergens.filter(item => item !== allergenId) :
				this.user.allergens.push(allergenId)
		},

		async sendComment() {
			const responseComment = await this.userRepository.sendComment(this.user.id, this.contactMessage);

			if (responseComment.result === 0) {
				alert('Comentario enviado con exito');
				this.contactMessage = "";
			} else {
				alert('Ha ocurrido un error');
			}
		},

		removeQueryParams() {
			let url = window.location.href;
			const urlParts = url.split('?');

			if (urlParts.length >= 2) {
				url = urlParts[0];
				window.history.replaceState({}, document.title, url);
			}
		},
	}
}
</script>

